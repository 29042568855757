/* eslint-disable no-underscore-dangle */
import { GLOBAL, afterDomContentLoaded } from 'saddlebag-browser';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';
import { FunnelEventsTracking as FunnelEventLogger } from 'saddlebag-user-tracking-events';

import '@skyscanner/backpack-web/bpk-stylesheets';
import '@skyscanner/backpack-web/bpk-stylesheets/font';
import '@skyscanner/backpack-web/bpk-stylesheets/larken';

import './public-path';
import './home.scss';
import { namespace, ACTION_TYPE, LOAD_STATUS } from './constants';
import FunnelEventsListener from './funnelEventsListener';
import { storeCashBackUserId } from './hotels-utils/services/backend-gateway/cash-back-user-id';
import { getQueryParam } from './hotels-utils/services/url/url-params';
import logMiniEventHelper from './mini-event/logMiniEventHelper';
import { loadExternalScripts } from './scriptLoader';
import '@skyscanner/backpack-web/bpk-component-horizontal-nav/src/BpkHorizontalNav.module.css';
import '@skyscanner/backpack-web/bpk-component-horizontal-nav/src/BpkHorizontalNavItem.module.css';
import { getAcceptLanguageFirstLang } from './utils';

const $window = GLOBAL.getWindow();
const clientSideContext = $window.__internal;
const rootComponent = `${namespace}.root`;

const loadSearchControls = async () => {
  const { default: hydrateSearchControls } = await import(
    /* webpackMode: "eager" */ './search-controls'
  );
  hydrateSearchControls();
};

const afterPageLoaded = async (data) => {
  const { pageType, useStrevdaRuntime } = data;
  const { default: hydrate } = await import('./hydrate');
  await hydrate();
  observer.publish('grappler-track', {
    eventName: 'PageLoaded',
    fullSchemaName: 'landing_pages.PageLoaded',
    message: {
      microsite: 'FALCON',
      page_type: pageType,
      accept_language: (data.culture && data.culture.acceptLanguage) || '',
      accept_language_first_lang:
        getAcceptLanguageFirstLang(
          (data.culture && data.culture.acceptLanguage) || null,
        ) || '',
      suggested_market: (data.culture && data.culture.suggestedMarket) || '',
    },
    isMiniEvent: true,
    consentRequired: true,
    microsite: {
      name: namespace,
    },
  });

  const cashBackUserId = getQueryParam(
    $window.location.href,
    'cashback_user_id',
  );
  if (cashBackUserId) {
    storeCashBackUserId(cashBackUserId);
  }

  const { name, variant } = data.distilledExperiment;
  if (name && variant) {
    observer.publish('tracking-event', {
      ga: [
        {
          category: `${data.pageType}-results-distilled-experiment`,
          action: variant,
          label: `${name} Results Variant ${variant.toUpperCase()}`,
        },
      ],
    });
  }

  await Promise.all([
    import(/* webpackMode: "eager" */ './header').then(
      ({ default: hydrateHeader }) => hydrateHeader(),
    ),
    import(/* webpackMode: "eager" */ './tag-manager').then(
      ({ default: tagManager }) => tagManager(),
    ),
    useStrevdaRuntime
      ? import(/* webpackChunkName: "StrevdaRuntime" */ 'strevda-runtime').then(
          (strevdaRuntime) => {
            const { strevdaRuntimeProps } = $window.__internal;
            delete $window.__internal.strevdaRuntimeProps;
            strevdaRuntime.init({ ...strevdaRuntimeProps });
          },
        )
      : Promise.resolve(),

    import(/* webpackMode: "eager" */ './ssculture').then(
      ({ default: setCultureCookie }) => setCultureCookie(),
    ),

    import(/* webpackMode: "eager" */ './gtmClient').then(
      ({ default: loadGtmClient }) => loadGtmClient(),
    ),
  ]);

  const { default: customNewRelicAttributes } = await import(
    /* webpackMode: "eager" */ './custom-new-relic-attributes'
  );
  customNewRelicAttributes($window);
};

(async () => {
  try {
    await loadExternalScripts();
  } catch (missing) {
    logger.logError(missing, {
      component: rootComponent,
    });
  }

  try {
    const funnelEventsLogger = new FunnelEventLogger(
      process.env.NODE_ENV === 'production' ? 'public' : 'public-sandbox',
      namespace,
    );
    const funnelEventsListener = new FunnelEventsListener(
      funnelEventsLogger,
      observer,
    );
    funnelEventsListener.subscribeToFunnelEvents();

    await loadSearchControls();
    afterDomContentLoaded(() =>
      afterPageLoaded(clientSideContext).catch((err) => {
        logger.logError(err, {
          component: rootComponent,
        });
      }),
    );
    logMiniEventHelper({
      action_type: ACTION_TYPE.PAGE_LOADED,
      load_status: LOAD_STATUS.LOADED,
    });
  } catch (err) {
    logger.logError(err, {
      component: rootComponent,
    });
  }
})();
